<template>
<div>
    <div class="row pt-3" :class="classes">
        <div class="col-xl-12">
            <card shadow type="secondary">
                <template v-slot:header>
                    <div class="bg-white border-0">
                        <div class="row align-items-center">
                            <div class="col-8">
                                <h3 class="mb-0">My C.A.B Experience</h3>
                            </div>
                            <modal v-model:show="modals.showExperienceModal" body-classes="p-0" modal-classes="modal-dialog-centered modal-lg">
                                <card type="secondary" shadow header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-4" class="border-0">
                                    <template v-slot:header>
                                        <div class="text-muted text-left mb--5">
                                            <h3>{{ addingExperience ? "Add" : "Edit" }} Assessment Job</h3>
                                        </div>
                                    </template>
                                    <form role="form" @submit.prevent="addingExperience ? addExperience() : editExperience(); modals.showExperienceModal = false;">
                                        <div class="">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Position</label>
                                                        <select :required="true" class="form-control form-control-alternative" v-model="experienceModel.position" style="width: 100%">
                                                            <option v-for="month in ['Lead Assessor', 'Technical Assessor', 'Trainee Assessor']" :key="month" :value="month">
                                                                {{ month }}
                                                            </option>
                                                        </select>
                                                    </div>                                                </div>
                                                <div class="col-md-8">
                                                    <base-input :required="true" alternative="" label="C A B Name" placeholder="Where did you do your assessment?" input-classes="form-control-alternative" v-model:value="experienceModel.company" />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <base-input :required="true" alternative="" label="Location" placeholder="Location" input-classes="form-control-alternative" v-model:value="experienceModel.location" />
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Day <span>*</span></label>
                                                        <select :required="true" class="form-control form-control-alternative" v-model="experienceModel.startDay" style="width: 100%">
                                                            <option v-for="month in 31" :key="month" :value="month">
                                                                {{ month }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Month <span>*</span></label>
                                                        <select :required="true" class="form-control form-control-alternative" v-model="experienceModel.startMonth" style="width: 100%">
                                                            <option v-for="month in months" :key="month" :value="month">
                                                                {{ month }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-2">
                                                    <base-input :required="true" alternative="" pattern="[1-2][09][0129][0-9]" label="Year" placeholder="Year" input-classes="form-control-alternative" v-model:value="experienceModel.startYear" />
                                                </div>
                                                <div class="col-md-1">
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <base-input alternative="" label="Description">
                                                            <textarea required rows="4" v-model="experienceModel.description" class="form-control form-control-alternative" placeholder="Fill in the standard and scopes assessed ...(Check for spelling errors)"></textarea>
                                                        </base-input>
                                                    </div>
                                                </div>
                                            </div>
                                            <button class="d-none" ref="experienceFormSubmitButton" type="submit"></button>
                                        </div>
                                    </form>
                                    <template v-slot:footer>
                                        <div class="">
                                            <base-button type="warning" @click=" modals.showExperienceModal = false" class="my-1">
                                                Close
                                            </base-button>
                                            <base-button type="info" @click="$refs.experienceFormSubmitButton.click();" class="my-1 float-right">
                                                Save
                                            </base-button>
                                        </div>
                                    </template>
                                </card>
                            </modal>
                            <modal v-if="experienceIndex < experienceList.length" v-model:show="modals.showDeleteExperienceModal" gradient="danger" modal-classes="modal-danger modal-dialog-centered">
                                <template v-slot:header>
                                    <h6 class="modal-title" id="modal-title-notification">
                                        Your attention is required
                                    </h6>
                                </template>
                                <div class="py-3 text-center">
                                    <i class="ni ni-bell-55 ni-3x"></i>
                                    <h4 class="heading mt-4">Deleting, Are you sure?</h4>
                                    <div class="pl-2 mt--2">
                                        <div class="row mb-2">
                                            <div class="col-md-12">
                                                <hr class="my-1" />
                                                <strong>
                                                    {{ experienceList[experienceIndex].position + "," }}
                                                </strong>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                {{ experienceList[experienceIndex].company }},
                                                <strong>
                                                    {{ experienceList[experienceIndex].location }}
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <template v-slot:footer>
                                    <base-button type="white" @click="deleteExperience();modals.showDeleteExperienceModal = false">
                                        Yes, I'm sure!
                                    </base-button>
                                    <base-button type="white" text-color="info" class="ml-auto" @click="modals.showDeleteExperienceModal = false">
                                        Close
                                    </base-button>
                                </template>
                            </modal>
                            <modal v-if="experienceIndex < experienceList.length" v-model:show="modals.showImageModal" gradient="secondary" modal-classes="modal modal-dialog-centered" style="overflow-y: scroll;">
                                <template v-slot:header>
                                    <div class="modal-title" id="modal-title-notification">
                                        <h6>
                                            This are evidences acquired during the course of the assessments.
                                        </h6>
                                    </div>
                                </template>
                                <div class=" text-center mt--5 row">
                                    <image-upload class="col-sm-4" type="Evidence" :url="'assessor/cab-evidence/' + experienceIndex + '/0' " v-model:src="experienceModel.evidenceURLs[0]"></image-upload>
                                    <image-upload class="col-sm-4" type="Evidence" :url="'assessor/cab-evidence/' + experienceIndex + '/1' " v-model:src="experienceModel.evidenceURLs[1]"></image-upload>
                                    <image-upload class="col-sm-4" type="Evidence" :url="'assessor/cab-evidence/' + experienceIndex + '/2' " v-model:src="experienceModel.evidenceURLs[2]"></image-upload>
                                </div>

                                <template v-slot:footer>
                                    <base-button type="white" text-color="info" class="ml-auto" @click="modals.showImageModal = false">
                                        Close
                                    </base-button>
                                </template>
                            </modal>

                            <div class="col-4 text-right">
                                <base-button :loading="modals.showExperienceModal || addInProgress" href="/profile" @click.prevent="experienceIndex = experienceList.length; modals.showExperienceModal = true; " class="btn btn-sm btn-default">
                                    <i class="fa fa-plus"></i>
                                    Experience
                                </base-button>
                            </div>
                        </div>
                    </div>
                </template>

                <form class="row">
                    <div readonly class="col-12 mb-3 btn-outline-danger text-default btn-block p-2" v-if="errorMessage != ''" @click="errorMessage = ''" style="background: #ffc8c8 !important; font-size: 0.775rem;border-radius:5px;">
                        <i class="fa fa-times float-right my-1 text-danger bg-white" style="height:16px;width:16px;border-radius:100%;padding:1px 0 0 4px;">
                        </i>
                        {{ errorMessage }}
                    </div>
                    <div class="col-lg-6 mb-4" v-for="(experience, index) in experienceList" :key="index">
                        <div class="card shadow" @mouseenter="experience.showDelete = true" @mouseleave="experience.showDelete = false">
                            <div class="card-header">
                                <h6 class="heading-small col-7 col-sm-11 text-muted my--2">
                                    {{ experience.startDay + " "  + experience.startMonth + ", " + experience.startYear }}
                                </h6>
                                <div class="float-right my--3">
                                    <base-button :class="experience.showDelete?'':'d-none'" :loading="deleteInProgress == experience.index" :disabled="editInProgress == experience.index" type="link" class="text-danger btn-sm my--3" @click="experienceIndex = index; modals.showDeleteExperienceModal = true;">
                                        Delete
                                    </base-button>
                                    <base-button :loading="editInProgress == experience.index" :disabled="deleteInProgress == experience.index" type="info" class="btn-sm my--3" @click="experienceIndex = index; modals.showExperienceModal = true;">
                                        Update
                                    </base-button>
                                </div>
                            </div>
                            <div class="card-body">
                                <div>
                                    <div class="pl-2 mt--2">
                                        <div class="row mb-2">
                                            <div class="col-md-12">
                                                <strong> {{ experience.position }} </strong>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                {{ experience.company }},
                                                <strong> {{ experience.location }} </strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <base-button class="card-footer btn-lg bg-success" type="info" @click="experienceIndex = index; modals.showImageModal = true;">
                                Preview Evidences
                            </base-button>
                        </div>
                    </div>
                </form>
            </card>
        </div>
    </div>
</div>
</template>

<script>
import AssessorService from "../../api/services/assessor.service";
import Badge from "../../components/Badge.vue";
import ImageUpload from '@/components/ImageUpload.vue';

export default {
    components: {
        Badge,
        ImageUpload
    },
    name: "cab-experience",
    props: {
        classes: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            experienceIndex: 0,
            modals: {},
            experienceList: [],
            newExperience: {},
            addInProgress: false,
            editInProgress: {},
            deleteInProgress: {},
            errorMessage: "",
            months: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ],
        };
    },
    computed: {
        addingExperience() {
            return this.experienceIndex == this.experienceList.length;
        },
        experienceModel() {
            return this.experienceIndex == this.experienceList.length ? this.newExperience : this.experienceList[this.experienceIndex];
        }
    },
    methods: {
        sortExperienceList(experienceList) {
            experienceList.forEach((degree, index) => {
                degree.index = index;
            });
            return experienceList.sort(
                (a, b) => new Date(b.startYear, this.months.indexOf(b.startMonth) + 1) - new Date(a.startYear, this.months.indexOf(a.startMonth) + 1)
            );
        },
        addExperience() {

            this.addInProgress = true;

            AssessorService.addCABJob(this.newExperience).then((response) => {

                this.newExperience = {}
                this.experienceIndex = 0;
                this.addInProgress = false;
                this.experienceList = this.sortExperienceList(response.data.result.cabexperience);
            }).catch((error) => {
                this.addInProgress = false;
                this.errorMessage = error.response == undefined ? "Something went wrong. <br> Please check your network and try again." : error.response.data.message
            });
        },
        editExperience() {

            var experience = this.experienceList[this.experienceIndex];

            this.editInProgress = experience.index;

            AssessorService.updateCABJob(experience.index, experience).then((response) => {

                this.experienceIndex = 0;
                this.editInProgress = {};
                this.experienceList = this.sortExperienceList(response.data.result.cabexperience);
            }).catch((error) => {
                this.editInProgress = {};
                this.errorMessage = error.response == undefined ? "Something went wrong. <br> Please check your network and try again." : error.response.data.message
            });
        },
        deleteExperience() {

            var experience = this.experienceList[this.experienceIndex];

            this.deleteInProgress = experience.index;

            AssessorService.deleteCABJob(experience.index).then((response) => {

                this.experienceIndex = 0;
                this.deleteInProgress = {};
                this.experienceList = this.sortExperienceList(response.data.result.cabexperience);
            }).catch((error) => {
                this.deleteInProgress = {};
                this.errorMessage = error.response == undefined ? "Something went wrong. <br> Please check your network and try again." : error.response.data.message
            });
        },
        setExperienceList(list) {
            return (this.experienceList = list.sort(
                (a, b) => new Date(b.startDate) - new Date(a.startDate)
            ));
        },
    },
    mounted() {
        AssessorService.getProfile().then((response) => {

            this.experienceList = this.sortExperienceList(response.data.result.cabexperience);
        })
    },
};
</script>

<style></style>
