// export configurations to be used globally


// http://localhost:8000/api/v1/
// http://192.168.250.147:8080/api/v1/
// https://c-stemp-lms.oa.r.appspot.com/api/v1/


export default {
  API_URL: "https://c-stemp-lms.oa.r.appspot.com/api/v1/" 
};
